


































import { Vue, Component } from 'vue-property-decorator';
import {AutenticadorService}  from '@/core/services/geral';

@Component
export default class Login extends Vue {
  logo = 'logo.png';
  hidePassword: boolean = true;
  loading: boolean = false;
  showPassword: boolean = false;
  acesso: {login: string, senha: string} = {
    login: '',
    senha: ''
  };
  fieldRules: any[] = [
    (v: any) => !!v || 'Campo obrigatório',
  ]
  service = new AutenticadorService();

  Autenticar(){
    this.loading = true;

    this.service.AutenticarUsuario(this.acesso)
      .then(res => {
        localStorage.setItem('sessionApp', JSON.stringify(res.data));
        this.$router.push({ name: 'home' });
      }, 
      err => {
          localStorage.clear();
          this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
        }).finally(() => this.loading = false);

  }

}
